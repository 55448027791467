<template>
  <UPopover
    :ui="{ popper: { placement: 'top-start' } }"
    mode="hover"
    class="flex"
  >
    {{ displayName }}
    <template #panel>
      <div>
        <p class="mb-3 text-lg font-semibold text-aubergine-900">
          {{ $t('parcel.bundleId') }}:
        </p>
        <div class="flex items-center">
          <p>
            {{
              bundleId && bundleId.length > 0
                ? bundleId
                : $t('parcel.noBundleId')
            }}
          </p>
          <MaterialIcon
            v-if="bundleId && bundleId.length > 0"
            v-tooltip.top="$t('general.copy')"
            icon="content_copy"
            class="ml-4"
            @click="copy(`${bundleId}`)"
          />
        </div>
        <p class="mb-3 text-lg font-semibold text-aubergine-900">
          {{ $t('parcel.medicineZINumber') }}:
        </p>
        <div class="flex items-center">
          <p>
            {{ originalArticleNumber ?? $t('parcel.noArticleNumber') }}
          </p>
          <MaterialIcon
            v-if="originalArticleNumber"
            v-tooltip.top="$t('general.copy')"
            icon="content_copy"
            class="ml-4"
            @click="copy(`${originalArticleNumber}`)"
          />
        </div>
      </div>
    </template>
  </UPopover>
</template>

<script lang="ts" setup>
const { copy } = useClipboard()

defineProps<{
  displayName: string
  bundleId?: string
  originalArticleNumber?: string
}>()
</script>
