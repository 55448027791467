import {
  getDocs,
  orderBy,
  query,
  type DocumentData,
  where
} from 'firebase/firestore'
import type { Deal } from '~/models'

export default function useDeals() {
  const { collection } = useFirebase()

  async function fetchDealsByUserId(userId: string) {
    return await getDocs<Deal, DocumentData>(
      query(
        collection('Deals'),
        where('UserId', '==', userId),
        orderBy('Created', 'desc')
      )
    )
  }

  async function getMedicineRollDistributionGroup(
    userId: string
  ): Promise<string | undefined> {
    const deals = await fetchDealsByUserId(userId)

    if (deals.empty) {
      return
    }

    return deals.docs[0].data().MedicineRollDistributionGroup
  }

  return {
    fetchDealsByUserId,
    getMedicineRollDistributionGroup
  }
}
