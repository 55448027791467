import {
  DocumentData,
  onSnapshot,
  query,
  QuerySnapshot,
  type Unsubscribe,
  where
} from 'firebase/firestore'
import type {
  CooledDeliveryType,
  Parcel,
  ParcelDeliveryControl
} from '~/models'

export const useDeliveryControlStore = defineStore('DeliveryControl', () => {
  const { getWelcomeGift, getUserAndAproData } = useAppUsers()
  const { getMedicineRollDistributionGroup } = useDeals()
  const { collection } = useFirebase()
  const { parcelStatusesOpen } = useParcelsData()

  const selectedView = ref<'delivery-control' | 'local'>('delivery-control')

  const parcelContainsCooledMedicine = ref<string[]>([])
  const parcelContainsCooledDeliveryMedicine = ref<
    { id: string; type?: CooledDeliveryType }[]
  >([])

  const loadingParcels = ref(false)

  const parcels = ref<Parcel[]>([]) // Used for realtime updates
  const deliveryControlParcels = ref<ParcelDeliveryControl[]>([]) // Mapped parcel to parcel with Control Delivery values

  const noParcelsFound = ref(false)
  const searchQuery = ref('')
  const snapshotDeliveryControl = ref<Unsubscribe | null>(null)

  watch(
    parcels,
    async (newVal) => {
      if (!parcels.value.length) {
        return
      }
      await handleParcelDocsWithUser()

      checkPickedAmount(newVal)
    },
    {
      deep: true
    }
  )

  async function handleParcelDocsWithUser() {
    const parcelData = await Promise.all(
      parcels.value.map(async (parcel) => {
        const AppUser = await getUserAndAproData(parcel.UserId)
        const medicineRollDistributionGroup =
          await getMedicineRollDistributionGroup(parcel.UserId)

        let WelcomeGift = null
        if (
          AppUser?.ConfirmedWelcomeGift &&
          !AppUser?.ConfirmedWelcomeGiftSentAt
        ) {
          WelcomeGift = await getWelcomeGift(AppUser.ConfirmedWelcomeGift)
        }

        const parcelData = {
          ...parcel,
          AppUser,
          MedicineRollDistributionGroup: medicineRollDistributionGroup
        } as ParcelDeliveryControl

        if (WelcomeGift) {
          parcelData.WelcomeGift = WelcomeGift
        }

        return parcelData
      })
    )

    deliveryControlParcels.value = await Promise.all(
      parcelData.map((parcel) => ({
        ...parcel,
        StorageLocation: parcel.StorageLocation ?? '',
        ParcelItems: parcel.ParcelItems.map((parcelItem) => {
          return {
            ...parcelItem,
            IsFilled: parcelItem.IsFilled ?? 'notFilled'
          }
        })
      })) as ParcelDeliveryControl[]
    )

    loadingParcels.value = false
  }

  function checkPickedAmount(parcels: Parcel[]) {
    // If data gets updated through Firebase, update deliveryControlParcels --> IsFilled and PickedAmount
    parcels.forEach((parcel) => {
      parcel.ParcelItems.forEach((parcelItem) => {
        const findParcel = deliveryControlParcels.value.find(
          (parcelData) => parcelData.id === parcel.id
        )

        const findParcelItem = findParcel?.ParcelItems.find(
          (parcelItemFind) => {
            return parcelItemFind.DisplayName === parcelItem.DisplayName
          }
        )

        if (!findParcelItem) {
          return false
        }

        if (!parcelItem.PickedAmount || parcelItem.PickedAmount === 0) {
          findParcelItem.IsFilled = 'notFilled'
          return
        }

        if (parcelItem.PickedAmount >= parcelItem.ExpectedAmount) {
          findParcelItem.IsFilled = 'filled'
        } else {
          findParcelItem.IsFilled = 'notFilled'
        }
        findParcelItem.PickedAmount = parcelItem.PickedAmount

        return true
      })
    })
  }

  async function fetchParcelsByBarcode(
    searchField: string,
    queryString: string
  ) {
    const parcelQuery = query(
      collection('Parcels'),
      where(searchField, 'array-contains-any', [queryString]),
      where('CurrentStatus', 'in', parcelStatusesOpen)
    )

    handleParcelSnapshot(parcelQuery)
  }

  function handleParcelSnapshot(parcelQuery: any) {
    snapshotDeliveryControl.value = onSnapshot(
      parcelQuery,
      async (querySnapshot: QuerySnapshot) => {
        parcels.value = await Promise.all(
          querySnapshot.docs.map((doc: DocumentData) => {
            return {
              ...(doc.data() as Parcel),
              id: doc.id,
              ref: doc.ref
            }
          })
        )

        if (querySnapshot.empty) {
          searchResultsEmpty()
        }
      }
    )
  }

  function searchResultsEmpty() {
    noParcelsFound.value = true
    loadingParcels.value = false
  }

  return {
    deliveryControlParcels,
    fetchParcelsByBarcode,
    handleParcelSnapshot,
    loadingParcels,
    noParcelsFound,
    parcelContainsCooledDeliveryMedicine,
    parcelContainsCooledMedicine,
    parcels,
    searchQuery,
    searchResultsEmpty,
    selectedView,
    snapshotDeliveryControl
  }
})
