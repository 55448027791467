import type { ParcelStatus } from '~/models'

export default function useParcelsData() {
  const { t } = useI18n()

  const parcelStatuses: ParcelStatus = {
    actionAMP: {
      color: 'green',
      label: t('parcel.actionAMP')
    },
    appointmentPlanned: {
      color: 'green',
      label: t('parcel.appointmentPlanned')
    },
    appointmentPlannerAvailable: {
      color: 'green',
      label: t('parcel.appointmentPlannerAvailable')
    },
    awaitingCustomer: {
      color: 'orange',
      label: t('parcel.awaitingCustomer')
    },
    callBack: {
      color: 'orange',
      label: t('parcel.callBack')
    },
    cancelled: {
      color: 'red',
      label: t('parcel.cancelled')
    },
    changed: {
      color: 'orange',
      label: t('parcel.changed')
    },
    changeDeliveryDate: {
      color: 'green',
      label: t('parcel.changeDeliveryDate'),
      description: t('parcel.changeDeliveryDateDescription')
    },
    checkPackage: {
      color: 'green',
      label: t('parcel.checkPackage')
    },
    collected: {
      color: 'green',
      label: t('parcel.collected')
    },
    created: {
      color: 'signal-blue',
      label: t('parcel.created')
    },
    delayed: {
      color: 'orange',
      label: t('parcel.delayed'),
      description: ' '
    },
    carrierDelayed: {
      color: 'orange',
      label: t('parcel.carrierDelayed'),
      description: ' '
    },
    delivered: {
      color: 'green',
      label: t('parcel.delivered')
    },
    disputed: {
      color: 'orange',
      label: t('parcel.disputed'),
      description: t('parcel.disputedDescription')
    },
    done: {
      color: 'green',
      label: t('parcel.done')
    },
    dosChange: {
      color: 'orange',
      label: t('parcel.dosChange')
    },
    firstIssue: {
      color: 'orange',
      label: t('parcel.firstIssue')
    },
    expressDelivery: {
      color: 'orange',
      label: t('parcel.expressDelivery')
    },
    inTransit: {
      color: 'green',
      label: t('parcel.inTransit')
    },
    labeled: {
      color: 'signal-blue',
      label: t('parcel.labeled')
    },
    letterSent: {
      color: 'green',
      label: t('parcel.letterSent')
    },
    longDelay: {
      color: 'red',
      label: t('parcel.longDelay')
    },
    noContact: {
      color: 'orange',
      label: t('parcel.noContact')
    },
    notCompleteDelay: {
      color: 'orange',
      label: t('parcel.notCompleteDelay')
    },
    orderedWithoutAppointment: {
      color: 'orange',
      label: t('parcel.orderedWithoutAppointment')
    },
    phoneNumberProblem: {
      color: 'orange',
      label: t('parcel.phoneNumberProblem')
    },
    planned: {
      color: 'green',
      label: t('parcel.planned')
    },
    postShipmentProblem: {
      color: 'orange',
      label: t('parcel.postShipmentProblem')
    },
    preRegistered: {
      color: 'green',
      label: t('parcel.preRegistered')
    },
    preRegisteredOnline: {
      color: 'green',
      label: t('parcel.preRegisteredOnline')
    },
    preRegisteredPhone: {
      color: 'green',
      label: t('parcel.preRegisteredPhone')
    },
    prescriptionDelay: {
      color: 'orange',
      label: t('parcel.prescriptionDelay'),
      description: t('parcel.prescriptionDelayDescription')
    },
    preShipmentProblem: {
      color: 'orange',
      label: t('parcel.preShipmentProblem')
    },
    quarantine: {
      color: 'orange',
      label: t('parcel.quarantine')
    },
    readyForSend: {
      color: 'green',
      label: t('parcel.readyForSend')
    },
    returned: {
      color: 'green',
      label: t('parcel.returned')
    },
    secondIssue: {
      color: 'orange',
      label: t('parcel.secondIssue')
    },
    sendLater: {
      color: 'orange',
      label: t('parcel.sendLater'),
      description: t('parcel.sendLaterDescription')
    },
    servicePoint: {
      color: 'green',
      label: t('parcel.servicePoint')
    },
    sorting: {
      color: 'green',
      label: t('parcel.sorting')
    },
    tempDelay: {
      color: 'orange',
      label: t('parcel.tempDelay')
    }
  }

  const parcelBackorderStatuses: ParcelStatus = {
    new: {
      color: 'signal-blue',
      label: t('backorder.parcelProblemStatus.new')
    },
    regressed: {
      color: 'orange',
      label: t('backorder.parcelProblemStatus.regressed')
    },
    solved: {
      color: 'signal-blue',
      label: t('backorder.parcelProblemStatus.solved')
    },
    problematic: {
      color: 'orange',
      label: t('backorder.parcelProblemStatus.problematic')
    }
  }

  const parcelStatusesFiltersOverview = [
    'servicePoint',
    'readyForSend',
    'created',
    'delivered',
    'cancelled',
    'returned',
    'carrierDelayed',
    'delayed',
    'prescriptionDelay',
    'notCompleteDelay',
    'labeled',
    'expressDelivery'
  ]

  const parcelStatusesOpen = [
    'created',
    'checkPackage',
    'readyForSend',
    'notCompleteDelay',
    'prescriptionDelay',
    'tempDelay',
    'longDelay',
    'delayed',
    'sendLater'
  ]

  const parcelPrintableStatuses = [
    ...parcelStatusesOpen,
    'expressDelivery',
    'appointmentPlanned'
  ]

  const parcelItemCancelReasons = [
    {
      value: '',
      text: t('parcel.selectAReason')
    },
    {
      value: 'noPrescription',
      text: t('parcel.noPrescription')
    },
    {
      value: 'delayed',
      text: t('parcel.delayed')
    },
    {
      value: 'alternative',
      text: t('parcel.alternative')
    },
    {
      value: 'removedFromWholesaleAssortment',
      text: t('parcel.removedFromWholesaleAssortment')
    },
    {
      value: 'withdrawn',
      text: t('parcel.withdrawn')
    },
    {
      value: 'customerEnough',
      text: t('parcel.customerEnough')
    },
    {
      value: 'customerOtherReason',
      text: t('parcel.customerOtherReason')
    }
  ]

  function cancelledReasonLabel(cancelledReason: string) {
    return parcelItemCancelReasons.find(
      (reason) => cancelledReason === reason.value
    )?.text
  }

  return {
    cancelledReasonLabel,
    parcelBackorderStatuses,
    parcelItemCancelReasons,
    parcelPrintableStatuses,
    parcelStatuses,
    parcelStatusesFiltersOverview,
    parcelStatusesOpen
  }
}
