import {
  arrayUnion,
  deleteDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  Timestamp,
  type DocumentData,
  type PartialWithFieldValue,
  type QuerySnapshot,
  type Transaction,
  updateDoc,
  where,
  Unsubscribe
} from 'firebase/firestore'
import type {
  CreateParcel,
  CreateParcelSplit,
  Parcel,
  ParcelItem,
  ParcelItemBase,
  ParcelItemSplit
} from '~/models'

export default function useParcels() {
  const {
    parcelsCompleted,
    parcelsOpen,
    loadingParcelsOpen,
    loadingParcelsCompleted
  } = storeToRefs(useSearchParcel())
  const { parcelStatusesOpen } = useParcelsData()
  const { getUserAndAproData } = useAppUsers()
  const { user } = storeToRefs(useUserStore())
  const { handleParcelSnapshot, searchResultsEmpty } = useDeliveryControlStore()
  const { reportError } = useSentry()

  const { formatTimestamp, toast } = useHelpers()
  const {
    addDoc,
    collection,
    collectionGroup,
    db,
    doc,
    docRef,
    collectionRef
  } = useFirebase()
  const { t } = useI18n()

  let snapshotParcelsOpen: Unsubscribe | null = null
  let snapshotParcelsCompleted: Unsubscribe | null = null

  async function fetchUserParcelsOpen(userId: string) {
    if (snapshotParcelsOpen) snapshotParcelsOpen() // unsubscribe from previous snapshot

    const userOpenParcelQuery = query(
      collection('Parcels'),
      where('UserId', '==', userId),
      where('CurrentStatus', 'in', parcelStatusesOpen),
      orderBy('DeliveryDate', 'asc')
    )

    snapshotParcelsOpen = onSnapshot(
      userOpenParcelQuery,
      async (querySnapshot: QuerySnapshot) => {
        handleParcelsOpen(querySnapshot)
      }
    )
  }

  async function handleParcelsOpen(querySnapshot: any) {
    const queryParcelsOpen = querySnapshot.docs.map((doc: DocumentData) => {
      return {
        ...doc.data(),
        id: doc.id,
        ref: doc.ref
      }
    })
    parcelsOpen.value = queryParcelsOpen
    loadingParcelsOpen.value = false
  }

  async function fetchUserParcelsCompleted(userId: string) {
    if (snapshotParcelsCompleted) snapshotParcelsCompleted() // unsubscribe from previous snapshot

    const userParcelsCompletedQuery = query(
      collection('Parcels'),
      where('UserId', '==', userId),
      orderBy('Created', 'desc'),
      limit(20)
    )

    snapshotParcelsCompleted = onSnapshot(
      userParcelsCompletedQuery,
      async (querySnapshot: QuerySnapshot) => {
        handleParcelsCompleted(querySnapshot)
      }
    )
  }

  async function handleParcelsCompleted(querySnapshot: any) {
    let queryParcelsCompleted = querySnapshot.docs.map((doc: DocumentData) => {
      return {
        ...doc.data(),
        id: doc.id,
        ref: doc.ref
      }
    })

    // Due to Firebases query limitations, we need to filter the parcels that are not open on the frontend
    queryParcelsCompleted = queryParcelsCompleted.filter((parcel: Parcel) => {
      return !parcelStatusesOpen.includes(parcel.CurrentStatus)
    })

    parcelsCompleted.value = queryParcelsCompleted
    loadingParcelsCompleted.value = false
  }

  const baseParcelItemAdd: ParcelItemBase = {
    BrandChange: false,
    CurrentStatus: 'aangemeld',
    OriginalArticleNumber: null,
    StatusHistory: [
      {
        Actor: `${user.value.displayName}`,
        Created: Timestamp.fromDate(new Date()),
        Status: 'aangemeld'
      }
    ],
    Created: Timestamp.fromDate(new Date()),
    Updated: Timestamp.fromDate(new Date())
  }

  async function createParcel(parcel: CreateParcel) {
    const parcelDocument = collection<Parcel>('Parcels')

    return await addDoc<Parcel>(parcelDocument, {
      ...parcel,
      CurrentStatus: 'created',
      CurrentStatusLabel: t('parcel.created'),
      ShipmentMethod: '',
      ShipmentReference: ''
    })
  }

  async function updateParcel(
    parcelId: string,
    payload: PartialWithFieldValue<Parcel>
  ) {
    try {
      return await updateDoc<Parcel, DocumentData>(doc(`Parcels/${parcelId}`), {
        ...payload,
        Updated: serverTimestamp()
      })
    } catch (error) {
      reportError('Error updating parcel', {
        error,
        parcelId,
        payload
      })
    }
  }

  async function checkParcelItemsCancelled(
    parcelItems: ParcelItem[]
  ): Promise<PartialWithFieldValue<Parcel>> {
    const checkAllCancelled = parcelItems.every((item) => item.CancelledReason)

    if (checkAllCancelled) {
      return {
        ParcelItems: parcelItems,
        CurrentStatus: 'cancelled',
        CurrentStatusLabel: t('parcel.cancelled'),
        StatusHistory: arrayUnion({
          Actor: `${user.value.displayName}`,
          Created: Timestamp.fromDate(new Date()),
          Status: 'cancelled'
        })
      }
    }

    return { ParcelItems: parcelItems }
  }

  function deleteParcel(parcelId: string) {
    return deleteDoc(doc(`Parcels/${parcelId}`))
  }

  async function fetchParcelsByAPROId(
    queryString: string,
    deliveryControl: boolean = false
  ) {
    const { docs: patientDocs } = await getDocs(
      query(
        collectionGroup('ExternalUserData'),
        where('PatientId', '==', queryString)
      )
    )

    if (!patientDocs[0]) {
      if (deliveryControl) {
        searchResultsEmpty()
        return
      }
      return []
    }

    if (patientDocs[0].ref.parent.parent?.id === undefined) return []

    const parcelQuery = query(
      collection('Parcels'),
      where('UserId', '==', patientDocs[0].ref.parent.parent.id),
      where('CurrentStatus', 'in', parcelStatusesOpen)
    )

    if (deliveryControl) {
      return handleParcelSnapshot(parcelQuery)
    }

    // Fetch parcels once
    const { docs: parcelDocs } = await getDocs(parcelQuery)
    return parcelDocs.map((doc: DocumentData) => ({
      ...(doc.data() as Parcel),
      id: doc.id,
      ref: doc.ref
    }))
  }

  type searchAproOrBarcode = 'APROId' | 'BundleIds'
  function handleSearchAPROorBarcode(queryString: string): {
    searchField: searchAproOrBarcode
    queryString: string
  } {
    if (queryString.length >= 8) {
      // Convert APRO barcode to Bundle id, they always start with leading 4 0's and last characters are 40
      if (/^0000.*40$/.test(queryString)) {
        queryString = queryString.slice(4, -2)
      }

      if (/^0.*40$/.test(queryString)) {
        queryString = queryString.slice(1, -2)
      }
      return { searchField: 'BundleIds' as searchAproOrBarcode, queryString }
    }
    return { searchField: 'APROId', queryString }
  }

  const loading = ref(false)

  /**
   * Split a parcel into multiple parcels
   */
  interface CalculateAmountToSplitParams {
    expectedAmount: number
    pickedAmount?: number | undefined
  }

  function calculateAmountToSplit({
    expectedAmount,
    pickedAmount = 0
  }: CalculateAmountToSplitParams): number {
    const amount = expectedAmount - pickedAmount
    return Math.max(amount, 0)
  }

  async function splitParcels({
    parcel,
    checkedParcelItems,
    parcelItemsSplit
  }: {
    parcel: Parcel
    checkedParcelItems: ParcelItemSplit[]
    parcelItemsSplit: ParcelItemSplit[]
  }): Promise<void> {
    loading.value = true

    const parcelItemToDuplicate = _cloneDeep(
      checkedParcelItems.filter(
        (item) => item.AmountToSplit && item.AmountToSplit > 0
      )
    )

    if (!parcelItemToDuplicate.length) {
      loading.value = false
      toast.error(t('parcel.nothingToSplit'))
      return
    }

    const currentParcelItemsToUpdate = parcelItemsSplit.reduce<ParcelItem[]>(
      (acc, item) => {
        const findCheckedItem = checkedParcelItems.find(
          (parcelItem) =>
            parcelItem.DisplayName === item.DisplayName &&
            parcelItem.Created.seconds === item.Created.seconds &&
            parcelItem.ExpectedAmount === item.ExpectedAmount
        )

        // Return if item is not checked
        if (!findCheckedItem) return [...acc, item]

        const { AmountToSplit, PickedAmount } = findCheckedItem || {}

        // Do not return parcelItem, if amount to split is higher or the same then expected which means fully split
        if (AmountToSplit && AmountToSplit >= item.ExpectedAmount) return acc

        // Remove AmountToSplit from parcelItem
        delete item.AmountToSplit

        // Return parcelItem if pickedAmount is not set (not changed)
        if (!PickedAmount) return [...acc, item]

        const updatedItem = {
          ...item,
          PickedAmount: +PickedAmount
        }

        // Return parcelItem with updated pickedAmount
        return [...acc, updatedItem]
      },
      []
    )

    // Remove amountToSplit from parcelItem before updating parcel
    currentParcelItemsToUpdate.forEach((item: ParcelItemSplit) => {
      delete item?.AmountToSplit
      delete item?.OnDemand
    })

    const parcelSplitValues = await splitCreateParcelWithCopiedItems(
      parcel,
      parcelItemToDuplicate
    )

    const parcelSplitDocRef = docRef(collectionRef(db, 'Parcels'))

    try {
      await runTransaction(db, async (transaction: Transaction) => {
        transaction.set(parcelSplitDocRef, {
          ...parcelSplitValues,
          Updated: serverTimestamp(),
          CurrentStatus: 'created',
          CurrentStatusLabel: t('parcel.created'),
          ShipmentMethod: '',
          ShipmentReference: ''
        } as Parcel)

        transaction.update(doc('Parcels', parcel.id), {
          ParcelItems: currentParcelItemsToUpdate
        })
      })

      toast.success(t('parcel.divided'))
      return
    } catch (error) {
      console.debug(error)
      toast.error(t('helpers.somethingWentWrong'))
    }
  }

  async function splitCreateParcelWithCopiedItems(
    parcel: Parcel,
    parcelItems: ParcelItemSplit[]
  ): Promise<CreateParcel | undefined> {
    const parcelItemsToUpdate = parcelItems.map((item) => {
      let amountToSplit
      if (item?.AmountToSplit) amountToSplit = +item.AmountToSplit
      return {
        ...item,
        ExpectedAmount: amountToSplit
      }
    })

    parcelItemsToUpdate.forEach((item) => {
      delete item?.PickedAmount
      delete item?.AmountToSplit
    })

    try {
      const parcelCreateDetails: CreateParcelSplit = {
        Created: parcel.Created,
        DeliveryDate: parcel.DeliveryDate,
        ParcelItems: parcelItemsToUpdate as ParcelItem[],
        StatusHistory: [
          {
            Actor: `${user.value.displayName}`,
            Created: Timestamp.fromDate(new Date()),
            Status: 'created'
          }
        ],
        UserId: parcel.UserId
      }

      if (parcel.PrescriptionNumbers) {
        parcelCreateDetails.PrescriptionNumbers = parcel.PrescriptionNumbers
      }

      if (parcel.BundleIds) parcelCreateDetails.BundleIds = parcel.BundleIds

      return parcelCreateDetails
    } catch (error) {
      console.debug('Creating Parcel went wrong:', error)
    }
  }

  async function parcelsAddAproUserData(parcels: Parcel[]) {
    return await Promise.all(
      parcels.map(async (parcel) => ({
        ...(await getUserAndAproData(parcel.UserId)),
        ...parcel,
        id: parcel.id,
        path: parcel.ref
      }))
    )
  }

  function isCancelled(parcelItem: ParcelItem): boolean {
    return !!parcelItem.CancelledReason
  }

  function isProblematicDate(
    date1?: Timestamp,
    date2?: Timestamp,
    formattedDate1?: string | null,
    formattedDate2?: string | null,
    inclusive: boolean = false
  ): boolean {
    if (!date1 || !date2) return false

    const comparison = inclusive ? date1 >= date2 : date1 > date2
    return comparison && formattedDate1 !== formattedDate2
  }

  function checkDeliveryDeadlineIsProblematic({
    parcelItem,
    parcelExpectedDeliveryDate
  }: {
    parcelItem: ParcelItem
    parcelExpectedDeliveryDate?: Timestamp
  }): boolean {
    if (isCancelled(parcelItem)) return false

    if (!parcelItem.DeliveryDeadline || !parcelExpectedDeliveryDate) {
      return false
    }

    const formattedDeliveryDeadline = formatTimestamp(
      parcelItem.DeliveryDeadline
    )
    const formattedExpectedDeliveryDate = formatTimestamp(
      parcelExpectedDeliveryDate
    )

    return (
      parcelItem.DeliveryDeadline < parcelExpectedDeliveryDate &&
      formattedDeliveryDeadline !== formattedExpectedDeliveryDate
    )
  }

  function checkOutOfStockUntilIsProblematic({
    parcelItem,
    parcelExpectedDeliveryDate
  }: {
    parcelItem: ParcelItem
    parcelExpectedDeliveryDate?: Timestamp
  }): boolean {
    if (isCancelled(parcelItem)) return false

    const formattedOutOfStockUntil = formatTimestamp(parcelItem.OutOfStockUntil)
    const formattedDeliveryDeadline = formatTimestamp(
      parcelItem.DeliveryDeadline
    )
    const formattedExpectedDeliveryDate = formatTimestamp(
      parcelExpectedDeliveryDate
    )

    const isAfterDeliveryDate = isProblematicDate(
      parcelItem.OutOfStockUntil,
      parcelExpectedDeliveryDate,
      formattedOutOfStockUntil,
      formattedExpectedDeliveryDate
    )

    const isAfterDeliveryDeadline = isProblematicDate(
      parcelItem.OutOfStockUntil,
      parcelItem.DeliveryDeadline,
      formattedOutOfStockUntil,
      formattedDeliveryDeadline,
      true
    )

    return isAfterDeliveryDate || isAfterDeliveryDeadline
  }

  onUnmounted(() => {
    if (snapshotParcelsOpen) snapshotParcelsOpen()
    if (snapshotParcelsCompleted) snapshotParcelsCompleted()
  })

  return {
    baseParcelItemAdd,
    calculateAmountToSplit,
    checkDeliveryDeadlineIsProblematic,
    checkOutOfStockUntilIsProblematic,
    checkParcelItemsCancelled,
    createParcel,
    deleteParcel,
    fetchParcelsByAPROId,
    fetchUserParcelsCompleted,
    fetchUserParcelsOpen,
    handleSearchAPROorBarcode,
    loading,
    parcelsAddAproUserData,
    splitParcels,
    updateParcel
  }
}
